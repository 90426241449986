import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { getPlural } from 'client/utils/plural';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';
import { getAdImageUrl } from 'site-modules/shared/components/native-ad/utils/utils';
import {
  svgCarTrims,
  svgColorPalette,
  svgPriceTag,
  svgStreeringWheel,
} from 'client/images/inline-svgs/ads/buildprice-ad-icons';

import './buildprice-at-a-glance-ads-10079-chal.scss';

import { Styles } from './buildprice-site-served-ad-styles';

const renderJellybean = (vehicleImg, photoDisclaimer, classes) => (
  <figure
    style={Styles.imgWrapper}
    className={classnames('w-100 mx-auto d-flex justify-content-center align-items-center pos-r', classes)}
  >
    <img style={Styles.coreImg} className="img-fluid rounded" src={vehicleImg} alt="" />
    {photoDisclaimer && (
      <figcaption className="w-100 text-right size-12 pos-a mb-0_25" style={Styles.figcaption}>
        {photoDisclaimer}
      </figcaption>
    )}
  </figure>
);

const renderAtGlanceItem = (icon, text, childs) => (
  <li style={Styles.li}>
    {icon}
    <ContentFragment componentToUse="span" classes="text-gray-darker">
      {text}
    </ContentFragment>
    {childs}
  </li>
);

const renderAtAGlance = creativeConfigData => {
  const { msrp: msrpConfig, trimNames, vehicleInfo } = creativeConfigData;
  const { extColorCnt, pkgCnt } = get(vehicleInfo, 'submodels[0]', {});
  const trimCnt = trimNames?.length;
  const makeName = vehicleInfo?.makeName;
  const msrp = msrpConfig?.trim();

  if (!msrp && !extColorCnt && !trimCnt && !pkgCnt) {
    return (
      <section className="at-a-glance-container pos-r">
        <span className="d-block mb-1 pr-md-1 text-gray-darker">
          Choose the trim, color, options, packages and more {!!makeName && `for your ${makeName}`}
        </span>
      </section>
    );
  }

  return (
    <section className="at-a-glance-container pos-r">
      <header className="size-14 mb-1_5">At a Glance:</header>
      <ul style={Styles.ul}>
        {!!extColorCnt &&
          renderAtGlanceItem(svgColorPalette, `${extColorCnt} ${getPlural('Color', extColorCnt)}`, null)}
        {!!trimCnt && renderAtGlanceItem(svgCarTrims, `${trimCnt} ${getPlural('Trim', trimCnt)}`, null)}
        {!!pkgCnt && renderAtGlanceItem(svgStreeringWheel, `${pkgCnt} ${getPlural('Package', pkgCnt)}`, null)}
        {!!msrp && renderAtGlanceItem(svgPriceTag, msrp, <span className="size-12 ml-0_25">starting MSRP</span>)}
      </ul>
    </section>
  );
};

export function BuildPriceAtAGlanceAds10079Chal({ creativeConfigData, mobile, ads10079Recipe }) {
  const { headline, cta, linkDisplayUrl, photoDisclaimer, isConquest, photo, logo } = creativeConfigData;

  const vehicleImg = getAdImageUrl(photo);

  const header = <ContentFragment>{headline}</ContentFragment>;

  const isChal1 = ads10079Recipe === 'chal1';
  const isChal2 = ads10079Recipe === 'chal2';
  const isNarrowClass = 'hidden-md-up';
  const isWideClass = 'hidden-sm-down';

  return (
    <Row
      className={classnames({
        'is-chal1': isChal1,
        'is-chal2': isChal2,
      })}
    >
      <Col xs={12} md={6} tag="section">
        {isConquest && (
          <ConquestRibbon style={{ height: '20px', lineHeight: '20px', top: '-18px', left: '-12px' }} classes="pos-a" />
        )}
        {!isConquest && (
          <img
            className={classnames('pos-a', {
              [`${isWideClass}`]: isChal1,
            })}
            alt=""
            src={logo}
            height={'24'}
            style={{ top: '-8px' }}
          />
        )}
        <header
          className={classnames('headline font-weight-bold text-gray-darkest', {
            'size-20': !mobile,
            [`${isWideClass}`]: isChal1,
            'mb-0_75 pr-2': isChal1,
            'mt-1_5': !isConquest,
            'mt-1 mt-md-1_5': isConquest,
            'mb-md-0_75': isChal2,
          })}
        >
          {header}
        </header>
        {renderJellybean(
          vehicleImg,
          photoDisclaimer,
          classnames(isNarrowClass, {
            'mt-2': isChal1,
          })
        )}
        {isChal1 && (
          <Fragment>
            {!isConquest && (
              <img
                className={classnames('pos-a', isNarrowClass)}
                alt=""
                src={logo}
                height={'24'}
                style={{ marginTop: '-24px' }}
              />
            )}
            <header
              className={classnames('headline font-weight-bold text-gray-darkest mt-1_5 mb-2', isNarrowClass, {
                'size-20': !mobile,
              })}
            >
              {header}
            </header>
          </Fragment>
        )}
        {renderAtAGlance(creativeConfigData)}
      </Col>
      <Col xs={12} md={6} tag="section" className="d-flex flex-column justify-content-center">
        {renderJellybean(vehicleImg, photoDisclaimer, isWideClass)}
        <div className="w-100 text-center cta-wrapper mx-auto mt-1_5 mt-md-0">
          <span className="btn btn-sm btn-outline-blue-50 py-0_5 px-0_75 px-md-0_5 size-16 font-weight-medium pos-r d-block mt-0">
            <CtaButtonText text={cta} />
          </span>
          <span className="medium text-blue-50 pos-r d-block oem-url-wrapper">{linkDisplayUrl}</span>
        </div>
      </Col>
    </Row>
  );
}

BuildPriceAtAGlanceAds10079Chal.propTypes = {
  creativeConfigData: PropTypes.shape({}).isRequired,
  mobile: PropTypes.bool.isRequired,
  ads10079Recipe: PropTypes.string,
};

BuildPriceAtAGlanceAds10079Chal.defaultProps = {
  ads10079Recipe: '',
};
